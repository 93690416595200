<template>
  <section class="w-full relative aspect-video max-h-[550px] items-center justify-center flex flex-col space-y-4 lg:space-y-12 py-20 px-5" style="background-size: 100% 100%;background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAOCAYAAABO3B6yAAAAAXNSR0IArs4c6QAAArhJREFUSEttlUGLFDEQhV9SSQ9eRRBvggdviwdBWDwpAyqy6t68yPr/f8B00p1EXlXS3bPrQpje3knqy3uvat27zw9NRDBNE6bppJ8SAhw8SqnIy4J5TrjMM+Y5I+cFaymorQHOw3uBF4ETgfcecK4voKGv1vpzQ+Nz/x2twb0/PzQJghgnnKYT4jQhSNBDCJCyAYyVlgXrWrhXv2OFO4T3cArBwg78YWkF0aIs3t91CHf77a8pEKMqQBAqwFNKKRvA5TJjTqkrUFUB56wgFSAEnw3A7bfXp1H0urhifrozC1iY8scYIRL0BmUlQD5YkBRoLVVvZBYQIMDLKN5tGPIPBQbIUMKo4L7e/2niCRBV/hgIIApAqVMiwAxTICsAlanc/D8LegbMf5P/ygq+6Tbwvbu7/20KBAJEhBBVTm58AjAnDaUpwN28/SMLKD8hjv5vICMP/fo84uePX42HECDEAeC1gAGwA5IqwOe8rBpOc2C3wBHk4P8osYXwyoIDwPcvZ7UghKA2UAEeTI/XxSywFkwawoUAtepx3ln6bfUMMP3aBU1t6tnfZD9aohacP364VkDCNYCGcFYVCLOuBFD9ewBZ/BDC7jkRn7TesGLMAZ5ye/O2iXi9OVXgcsxAbVpszAHKz46gLdUSqFnhDGHX7C3Inh8KWLewZccMsL8cLLh5/bKJfwTgxCxYCzIVYPq3GWBDiB1A6VnchzEHGD7OABa1WcHPffrZINohAPfm+bPG8OjtmQG9jQFwDnD0jtvnzADaDRh0dg+HFpcGkKtPPQOoqpZCPLJkQLhXJzQR1+U3GzjhSMp+V4CckVPFAqD0AcO7CoBAECogYlTAdvNaOwQtaXxv/xv6KNDvuhceTTzA6XvMAHeoBcuixVODAtQOwLsSQJeDdcEYwV3+Uq3o2DM+9wQA/wDdv0BT2+UlAwAAAABJRU5ErkJggg==');">
    <div class="z-0 backdrop-blur-xxl">
      <img
        class="absolute bottom-0 inset-0 object-cover top-0 left-0 right-0 h-full w-full max-w-none z-0"
        :src="bg"
        :srcset="srcset"
        fetchpriority="highest"
        preload
        prefetch
        alt="A Gallery with everything in one place."
      />
      <div class="z-10 absolute bottom-0 inset-0 bg-gradient-to-t from-black-900/30 to-transparent" />
    </div>
    <div class="hidden sm:mb-8 sm:flex sm:justify-center">
      <div class="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 ring-1 ring-lightgray-100/10 hover:ring-lightgray-100/20">
        <span class="text-lightgray-100">
          Announcing our 50k Sequenced 360° HDRI'S. <NuxtLink to="/hdris/" class="font-semibold text-orange-400"><span class="absolute inset-0" aria-hidden="true" />Read more <span aria-hidden="true">&rarr;</span></NuxtLink>
        </span>
      </div>
    </div>
    <h1 class="z-10 text-4xl lg:text-6xl font-bold text-lightgray-100 text-center">
      A Gallery with everything<br class="hidden lg:block" /> in one place<span class="text-orange-500">.</span>
    </h1>
    <div class="z-10 text-center space-y-2">
      <p class="text-lg lg:text-xl text-lightgray-100 font-medium">
        Cut your searching time in half for VFX ready assets.
      </p>
      <p class="text-lg lg:text-xl text-lightgray-100 font-medium">
        MattePaint is built for teams at scale with lightning fast search and RAW asset delivery.
      </p>
    </div>
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="z-10  !mt-12"
    >
      <path d="M12.1699 12.5146L5.5293 5.87402C5.07031 5.41504 5.07031 4.67285 5.5293 4.21875L6.63281 3.11523C7.0918 2.65625 7.83398 2.65625 8.28809 3.11523L12.9951 7.82227L17.7021 3.11523C18.1611 2.65625 18.9033 2.65625 19.3574 3.11523L20.4707 4.21387C20.9297 4.67285 20.9297 5.41504 20.4707 5.86914L13.8301 12.5098C13.3711 12.9736 12.6289 12.9736 12.1699 12.5146ZM13.8301 21.8896L20.4707 15.249C20.9297 14.79 20.9297 14.0479 20.4707 13.5937L19.3672 12.4902C18.9082 12.0312 18.166 12.0312 17.7119 12.4902L13 17.1924L8.29297 12.4854C7.83398 12.0264 7.0918 12.0264 6.6377 12.4854L5.5293 13.5889C5.07031 14.0478 5.07031 14.79 5.5293 15.2441L12.1699 21.8848C12.6289 22.3486 13.3711 22.3486 13.8301 21.8896Z" fill="#F09E47" />
    </svg>
  </section>
</template>

<script>
import xlbg from '~/assets/img/home/hero/2x.jpg';
import lbg from '~/assets/img/home/hero/1x.jpg';
import mbg from '~/assets/img/home/hero/mobile.jpg';

export default {
  data() {
    return {
      bg: mbg, // default image
      srcset: `${mbg} 500w, ${lbg} 1024w, ${xlbg} 1920w`,
    };
  }
}
</script>
