<template>
  <div>
    <MainHeader />
    
    <HomeHero class="z-10" />

    <HomeLogoWall />

    <div class="hidden md:flex justify-center p-4 md:p-8 mb-4 md:mb-0">
      <div class="inline-flex rounded-md shadow">
        <NuxtLink to="/register/" class="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-500 px-5 py-3 text-base font-medium text-white hover:bg-orange-400">
          Sign up for free
        </NuxtLink>
      </div>
    </div>

    <HomeFeatures />

    <LazyHomeQuote />

    <section class="flex flex-col space-y-10 justify-center w-full max-w-8xl items-center m-auto py-10 md:py-20 px-4">
      <h2 class="text-3xl md:text-4xl lg:text-6xl font-bold text-lightgray-100 text-center uppercase">
        10,000+<br class="md:hidden" /> HDRI SKIES<span class="text-orange-500">!</span>
      </h2>
      <p class="text-lg font-medium text-lightgray-200 w-full max-w-4xl text-center">
        MattePaint provides your artists with a unique multi-resolution RAW format, 50k sequenced 360° HDRI's, masked assets and more! Check out the video for more.
      </p>
      <div class="inline-flex rounded-md shadow">
        <NuxtLink to="/hdris/" alt="Learn about our HDRI sequences" class="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-500 px-5 py-3 text-base font-medium text-white hover:bg-orange-400">
          Learn about our HDRI sequences
        </NuxtLink>
      </div>
    </section>

    <LazyHomeVideoCta />

    <LazyHomeFeatureList />

    <LazyHomePricing />

    <LazyPricingPlansCommercialGlobalLicense class="!py-16" />

    <LazyPricingPlansTestimonialsCommercial />

    <LazyHomeTrustedBy />

    <LazyHomeCreateInspiringWorlds />

    <MainFooter />
  </div>
</template>

<script>
const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: 'https://mattepaint.com',
  description: 'The highest quality reference photography for artists, architecture, visual effects, film and games.',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://mattepaint.com/',
  },
  potentialAction: {
    '@type': 'SearchAction',
    'target': 'https://mattepaint.com/gallery/search/{search_term_string}/',
    'query-input': 'required name=search_term_string'
  },
  image: {
    '@type': 'ImageObject',
    url: 'https://storage.googleapis.com/mattepaint/mattepaint_logo.jpg',
    'width': '1136',
    'height': '1128'
  },
  publisher: {
    '@type': 'Organization',
    name: 'MattePaint',
    legalName: 'MattePaint Pty Ltd',
    foundingDate: '2016',
    url: 'https://mattepaint.com/',
    logo: {
      '@type': 'ImageObject',
      url: 'https://storage.googleapis.com/mattepaint/mattepaint_logo.jpg',
      'width': '1136',
      'height': '1128'
    },
    sameAs: [
      'https://www.facebook.com/mattepaint/',
      'https://twitter.com/MattePaint_Ltd',
      'https://www.instagram.com/mattepaint_ltd/',
      'https://www.youtube.com/mattepaint',
      'https://www.linkedin.com/company/mattepaint/',
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      email: 'support@mattepaint.com',
      url: 'https://mattepaint.com',
    },
    founders: [
      {
        '@type': 'Person',
        name: 'Conrad Allan',
        jobTitle: 'CEO',
        url: 'https://mattepaint.com',
        sameAs: 'https://twitter.com/ConradWAllan',
      },
      {
        '@type': 'Person',
        name: 'Daniel Lang',
        jobTitle: 'CTO',
        url: 'https://mattepaint.com',
        sameAs: 'https://twitter.com/mavrickmaster',
      },
    ],
    email: 'support@mattepaint.com',
  }
};
export default {
  setup() {

    useServerSeoMeta({
      title: 'MattePaint | A Photo gallery with everything in one place',
      ogTitle: 'MattePaint | A Photo gallery with everything in one place',
      description: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.',
      ogDescription: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.',
      ogImage: 'https://assets-cdn.mattepaint.com/seo_home.jpg',
      twitterTitle: 'MattePaint | A Photo gallery with everything in one place',
      twitterDescription: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.',
      twitterImage: 'https://assets-cdn.mattepaint.com/seo_home.jpg',
      twitterCard: 'summary_large_image'
    });

    useSeoMeta({
      ogTitle: 'MattePaint | A Photo gallery with everything in one place',
      ogUrl: 'https://mattepaint.com/',
      ogDescription: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.',
      description: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.',
      ogImage: 'https://assets-cdn.mattepaint.com/seo_home.jpg',
      twitterTitle: 'MattePaint | A Photo gallery with everything in one place',
      twitterDescription: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.',
      twitterImage: 'https://assets-cdn.mattepaint.com/seo_home.jpg',
      twitterCard: 'summary_large_image'
    })

    useHead({
      title: 'MattePaint | A Photo gallery with everything in one place',
      meta: [{ name: 'description', content: 'Streamline your search for VFX ready assets with MattePaint\'s comprehensive photo gallery. Explore our collection now.' }],
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(jsonLd),
        },
      ],
      htmlAttrs: {
        lang: 'en'
      },
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          href: '/favicon.png'
        }
      ]
    })
  },
  data() {
    return {
      ready: false
    };
  },
  mounted() {
    this.ready = true;
  }
}
</script>